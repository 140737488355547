<template>
  <v-card class="card-shadow border-radius-xl">
    <v-card-text class="px-0 py-0">
      <v-simple-table class="table border-radius-xl">
        <template v-slot:default>
          <thead>
            <tr>
              <th
                class="text-left text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                ID
              </th>
              <th
                v-for="(hname, hindex) in headerNames"
                :key="'header' + hindex"
                class="text-left text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                {{ hname }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(item, i) in topics"
              :key="item.id"
              @click="_clickRow(item)"
            >
              <td class="text-secondary">{{ i + 1 }}</td>
              <td :class="{ 'border-bottom': i != topics.length - 1 }">
                {{ item.title }}
              </td>
              <td
                class="text-sm text-secondary mb-0"
                :class="{ 'border-bottom': i != topics.length - 1 }"
              >
                {{ item.function }}
              </td>
              <td
                class="text-h5"
                :class="{ 'border-bottom': i != topics.length - 1 }"
              >
                <div class="d-flex align-center">
                  <span
                    class="text-dark text-xs"
                    style="font-size: 10px !important"
                    >{{ formatXDate(item.createdAt) }}</span
                  >
                </div>
              </td>

              <td
                :class="{ 'border-bottom': i != topics.length - 1 }"
                class="text-center"
              >
                <small
                  style="cursor: pointer"
                  @click.stop="_detail(item.videoUrl)"
                  small
                  class="border-radius-sm text-xxs text-white me-1 shadow-none font-weight-bold px-3 py-1 btn-warning bg-gradient-warning"
                  elevation="0"
                  :ripple="false"
                  v-if="item.videoUrl && isYoutube(item.videoUrl)"
                  title="Контентоо шалгаx"
                  >video</small
                >

                <small
                  style="cursor: pointer; background: #eef4fa; color: #007aff"
                  @click.stop="detail(item.slideUrl)"
                  small
                  class="border-radius-sm text-xxs me-1 shadow-none font-weight-bold px-3 py-1"
                  elevation="0"
                  :ripple="false"
                  v-if="item.slideUrl"
                  title="Контентоо шалгаx"
                  >лекц</small
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <v-dialog v-model="showSlide">
      <!-- <iframe
        width="100%"
        height="800"
        v-if="
          slideId != null && slideId != undefined
        "
        v-bind:src="slideId"
        frameborder="0"
        allowfullscreen
      ></iframe> -->

      <div id="iframecontainer" v-html="slideId"></div>
    </v-dialog>
    <v-dialog v-model="showVideo">
      <iframe
        width="100%"
        height="800"
        v-if="
          videoId != null &&
          videoId != '' &&
          videoId.toLowerCase().indexOf('youtube') != -1
        "
        v-bind:src="getYoutubeIframe(videoId)"
        frameborder="0"
        allowfullscreen
      ></iframe>
    </v-dialog>
  </v-card>
</template>
<script>
const fb = require("@/firebaseConfig.js");
import myfunction from "@/components/global/myfunction.js";
import { sync } from "vuex-pathify";
export default {
  name: "light-table-transparent",
  data() {
    return {
      dialogDelete: false,
      topics: null,
      editedIndex: -1,
      editedItem: {
        title: null,
        description: null,
        url: null,
      },
      addNewItem: false,
      videoId: null,
      showVideo: false,

      showSlide: null,
      slideId: false,
    };
  },
  mixins: [myfunction],
  computed: {
    ...sync("*"),
  },
  props: {
    path: {
      type: String,
      required: true,
    },
    // query: {
    //   type: Object,
    //   required: true,
    // },
    headerNames: {
      type: Array,
      required: true,
    },
    // bodyNames: {
    //   type: Array,
    //   required: true,
    // },
    addButtonText: {
      type: String,
      required: true,
    },
  },
  watch: {
    showVideo(val) {
      if (val == false) this.videoId = null;
    },
  },
  created() {
    console.log(this.path);
    fb.db
      .collection(this.path)
      .orderBy("createdAt", "asc")
      .where("public", "==", true)
      .onSnapshot((docs) => {
        this.topics = [];
        docs.forEach((doc) => {
          let topic = doc.data();
          topic.id = doc.id;
          topic.ref = doc.ref;
          this.topics.push(topic);
        });
      });
  },
  methods: {
    _makeOpen(item) {
      if (item.public) item.ref.update({ public: !item.public });
      else item.ref.update({ public: true });
    },
    isYoutube(videoUrl) {
      return videoUrl.includes("youtube");
    },
    _deleteTopic(item) {
      // this.editedIndex = this.topics.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    _clickRow(item) {
      this.editedIndex = this.topics.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.addNewItem = true;
    },
    getYoutubeId(url) {
      const regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);

      return match && match[2].length === 11 ? match[2] : null;
    },
    getYoutubeIframe(url) {
      if (
        url != null &&
        url != "" &&
        url.toLowerCase().indexOf("youtube") != -1
      ) {
        var id = this.getYoutubeId(url);
        return "https://www.youtube.com/embed/" + id;
      }
      return null;
    },
    _addTopic() {
      this.addNewItem = true;
    },
    _close() {
      this.addNewItem = false;
      this.$nextTick(() => {
        this.editedItem = {};
        this.editedIndex = -1;
      });
    },
    _save() {
      if (this.editedIndex > -1) {
        this.editedItem.ref.update(this.editedItem).then(() => {
          this._close();
        });
      } else {
        this.editedItem.createdAt = new Date();
        this.editedItem.public = false;
        fb.db
          .collection(this.path)
          .doc()
          .set(this.editedItem, { merge: true })
          .then(() => {
            this._close();
          });
      }
    },
    _detail(url) {
      this.addNewItem = false;
      this.videoId = url;
      this.showVideo = !this.showVideo;
    },
    detail(url) {
      this.addNewItem = false;
      this.slideId = url;
      this.showSlide = !this.showSlide;
    },
    _closeDeleteDialog() {
      this.dialogDelete = false;
    },
    _deleteItemConfirm() {
      this.editedItem.ref.delete().then(() => {
        this.dialogDelete = false;
        this._close();
      });
    },
  },
};
</script>
<style>
#iframecontainer iframe {
  width: 100% !important;
}
</style>
