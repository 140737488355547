<template>
  <v-container fluid class="px-6 py-6" v-if="lesson">
    <v-row justify="end">
      <v-col lg="8" cols="12" v-if="colIgnore">
        <v-card class="border-radius-xl card-shadow">
          <div class="d-flex pb-0 px-4 py-4">
            <h6 class="text-h6 my-auto text-typo font-weight-bold me-5">
              {{ lesson.name }}
            </h6>
            <v-tabs v-model="tab" left class="overflow-hidden">
              <v-tabs-slider></v-tabs-slider>
              <v-tab
                v-for="(tabText, tabIndex) in tabTexts"
                :href="'#tab-' + (tabIndex + 1)"
                :key="'tab' + tabIndex"
              >
                {{ tabText }}
              </v-tab>
            </v-tabs>
          </div>

          <div class="px-4 py-4 mt-2">
            <v-tabs-items v-model="tab">
              <v-tab-item key="1" value="tab-1">
                <StudentTopicTable
                  v-if="lesson"
                  :path="lesson.ref.path + '/topics'"
                  :headerNames="['Сэдвийн нэр', 'Үзэлт', 'Огноо']"
                  :addButtonText="'Сэдэв'"
                >
                </StudentTopicTable>
              </v-tab-item>
              <!-- <v-tab-item key="2" value="tab-2">
                <StudentHwTable
                  v-if="lesson"
                  :lessonRef="lesson.ref.path"
                  :headerNames="['Сэдвийн нэр', 'Үзэлт', 'Огноо']"
                  :addButtonText="'Сэдэв'"
                >
                </StudentHwTable>
              </v-tab-item>
              <v-tab-item key="3" value="tab-3">
                <StudentsLessonScore
                  :studentScore="studentScore"
                  :userData="userData"
                  :semesterNumber="3"
                >
                </StudentsLessonScore>
              </v-tab-item> -->
            </v-tabs-items>
          </div>
        </v-card>
      </v-col>
      <v-col lg="4" v-if="tab != null && tab == 'tab-3'"> </v-col>
      <v-col :lg="colWidth" cols="12" v-else>
        <v-card class="card-shadow border-radius-xl h-100">
          <div class="px-4 pt-4">
            <div class="d-flex flex-row">
              <v-col class="pa-0 text-center" cols="'auto">
                <p class="mb-0 mt-16">Хичээлээ жагсаалтаар үзэх</p>
                <v-btn
                  outlined
                  color="#fff"
                  @click="_viewAsPreview"
                  class="font-weight-bolder bg-gradient-warning py-4 px-7"
                  >Xичээл үзэx</v-btn
                >
              </v-col>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
/*eslint-disable*/
import StudentHwTable from "./StudentHwTable.vue";
import VueQRCodeComponent from "vue-qrcode-component";
import myfunction from "@/components/global/myfunction.js";
import StudentTopicTable from "./StudentTopicTable.vue";
import StudentsLessonScore from "./StudentsLessonScore";
import { sync } from "vuex-pathify";
const fb = require("@/firebaseConfig.js");
export default {
  name: "smart-home",
  components: {
    StudentHwTable,
    StudentTopicTable,
    StudentsLessonScore,
    VueQRCodeComponent,
  },
  mixins: [myfunction],
  data() {
    return {
      allStudents: null,
      selectedAddStudents: [],

      addStudentToLessonDialog: false,
      selectedClassGroup: null,
      selectedDepartment: null,

      addClassGroup: false,
      school: null,
      semesterNumber: null,
      showScores: false,
      sidebarColor: "success",
      studentScore: null,
      lesson: null,
      tab: null,
      tabClassGroups: null,
      tabTexts: [],

      colIgnore: true,
      colWidth: 4,

      search: "",

      stQuery: null,
      stPath: null,
      stHeaderNames: null,
      stBodyNames: null,

      removeStudentDialog: false,
      removeAllStudentsDialog: false,
      removeSeletectedStudent: null,

      filterSelectedDepartment: null,
      filterSelectedDepartmentClassGroup: null,
      departments: null,
      unelgeeDialog: false,
      unelgeenuud: null,
      selectedUnelgee: null,
    };
  },
  props: {
    path: {
      type: String,
      required: true,
    },
  },
  watch: {},
  computed: {
    ...sync("*"),
    zzschool() {
      return this.userData.ppschool;
    },
  },
  methods: {
    _viewAsPreview() {
      this.$router.push({
        name: "PageLessonPreview",
        params: { path: this.path },
      });
    },
  },

  async created() {
    if (fb.EYESH_APP) {
      this.tabTexts = ["Сэдвүүд"];
    } else {
      // this.tabTexts = ["Сэдвүүд", "Даалгаврууд", "Улирлын дүн"];
      this.tabTexts = ["Сэдвүүд"];
    }
    // this.userData.school.ref
    //   .collection("unelgeenuud")
    //   .where("open", "==", true)
    //   .get()
    //   .then((docs) => {
    //     this.unelgeenuud = [];
    //     docs.forEach((doc) => {
    //       let unelgee = doc.data();
    //       unelgee.id = doc.id;
    //       unelgee.ref = doc.ref;
    //       console.log(unelgee);
    //       this.unelgeenuud.push(unelgee);
    //     });
    //   });
    console.log(this.path);
    await fb.db.doc(this.path).onSnapshot((doc) => {
      console.log(doc);
      let lesson = doc.data();
      if (lesson.lessonRef) {
        lesson.lessonRef.get().then((doc) => {
          let origLesson = doc.data();
          origLesson.id = doc.id;
          origLesson.ref = doc.ref;
          this.lesson = origLesson;
        });
      } else {
        let lesson = doc.data();
        lesson.id = doc.id;
        lesson.ref = doc.ref;
        this.lesson = lesson;
      }
    });
  },
};
</script>
