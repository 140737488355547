<template>
  <v-card class="card-shadow border-radius-xl" v-if="homeworks">
    <v-card-text class="px-0 py-0">
      <v-simple-table class="table border-radius-xl">
        <template v-slot:default>
          <thead>
            <tr>
              <th
                class="
                  text-left text-uppercase text-secondary text-xxs
                  font-weight-bolder
                  opacity-7
                "
              >
                ID
              </th>
              <th
                v-for="(hname, hindex) in headerNames"
                :key="'header' + hindex"
                class="
                  text-left text-uppercase text-secondary text-xxs
                  font-weight-bolder
                  opacity-7
                "
              >
                {{ hname }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in homeworks" :key="item.id">
              <td class="text-secondary">{{ i + 1 }}</td>
              <td :class="{ 'border-bottom': i != homeworks.length - 1 }">
                {{ item.title }}
              </td>
              <td
                v-if="item.score"
                class="text-sm text-secondary mb-0"
                :class="{ 'border-bottom': i != homeworks.length - 1 }"
              >
                {{ item.score }}
              </td>
              <td
                v-else
                class="text-sm text-secondary mb-0"
                :class="{ 'border-bottom': i != homeworks.length - 1 }"
              >
                дүгнэгдээгүй
              </td>
              <td
                class="text-h5"
                :class="{ 'border-bottom': i != homeworks.length - 1 }"
              >
                <div class="d-flex align-center">
                  <span class="text-dark text-xs">{{
                    formatXDate(item.createdAt)
                  }}</span>
                </div>
              </td>

              <td
                :class="{ 'border-bottom': i != homeworks.length - 1 }"
                class="text-center"
              >
                <small
                  v-if="checkIfHwDone(item)"
                  style="cursor: pointer"
                  @click="
                    checkHw(item);
                    attachmentDialog = !attachmentDialog;
                    attachmentRef = item.ref;
                    attachmentIndex = i;
                  "
                  small
                  class="
                    border-radius-sm
                    text-xxs text-white
                    me-1
                    shadow-none
                    font-weight-bold
                    px-3
                    py-1
                    btn-primary
                    bg-gradient-primary
                  "
                  elevation="0"
                  :ripple="false"
                  >Даалгавар Хураалгасан</small
                >
                <small
                  v-else
                  style="cursor: pointer"
                  @click="
                    checkHw(item);
                    attachmentDialog = !attachmentDialog;
                    attachmentRef = item.ref;
                    attachmentIndex = i;
                  "
                  small
                  class="
                    border-radius-sm
                    text-xxs text-white
                    me-1
                    shadow-none
                    font-weight-bold
                    px-3
                    py-1
                    btn-warning
                    bg-gradient-warning
                  "
                  elevation="0"
                  :ripple="false"
                  >Даалгавар Хураалгах</small
                >
                <small
                  style="cursor: pointer"
                  @click.stop="_detail(item.videoUrl)"
                  small
                  class="
                    border-radius-sm
                    text-xxs text-white
                    me-1
                    shadow-none
                    font-weight-bold
                    px-3
                    py-1
                    btn-warning
                    bg-gradient-warning
                  "
                  elevation="0"
                  :ripple="false"
                  v-if="item.videoUrl && isYoutube(item.videoUrl)"
                  title="Контентоо шалгаx"
                  >video</small
                >

                <small
                  style="cursor: pointer"
                  @click.stop="detail(item.slideUrl)"
                  small
                  class="
                    border-radius-sm
                    text-xxs text-white
                    me-1
                    shadow-none
                    font-weight-bold
                    px-3
                    py-1
                    btn-info
                    bg-gradient-info
                  "
                  elevation="0"
                  :ripple="false"
                  v-if="item.slideUrl"
                  title="Контентоо шалгаx"
                  >лекц</small
                >
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
    <v-dialog v-model="attachmentDialog" width="800" v-if="!loading">
      <v-card rounded="xl" class="pa-3">
        <v-card-title class="px-3">
          Файл xавсаргаx, /Google Drive..гм/ линк энд болно!
        </v-card-title>
        <v-card-actions>
          <v-text-field v-model="attachmentLink"></v-text-field>
        </v-card-actions>
        <v-card-actions class="mt-n5">
          <v-textarea
            v-model="attachmentDescription"
            hide-details
            auto-grow
            outlined
            label="Даалгавар бичиx"
            color="rgba(0,0,0,.6)"
            value="Say a few words about who you are or what you're working on."
            class="
              font-size-input
              border
              text-light-input
              border-radius-md
              mt-2
            "
          ></v-textarea>
        </v-card-actions>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="attachmentDialog = false">Цуцлах</v-btn>
          <v-btn class="bg-gradient-primary" @click="submitHw()" dark
            >Хадгалах</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showSlide">
      <!-- <iframe
        width="100%"
        height="800"
        v-if="
          slideId != null && slideId != undefined
        "
        v-bind:src="slideId"
        frameborder="0"
        allowfullscreen
      ></iframe> -->

      <div id="iframecontainer" v-html="slideId"></div>
    </v-dialog>
    <v-dialog v-model="showVideo">
      <iframe
        width="100%"
        height="800"
        v-if="
          videoId != null &&
          videoId != '' &&
          videoId.toLowerCase().indexOf('youtube') != -1
        "
        v-bind:src="getYoutubeIframe(videoId)"
        frameborder="0"
        allowfullscreen
      ></iframe>
    </v-dialog>
  </v-card>
</template>
<script>
import { sync } from "vuex-pathify";
import myfunction from "@/components/global/myfunction.js";
const fb = require("@/firebaseConfig.js");
export default {
  name: "light-table-transparent",
  data() {
    return {
      dialogDelete: false,
      editedIndex: -1,
      editedItem: {
        title: null,
        description: null,
        url: null,
      },
      videoId: null,
      showVideo: false,

      showSlide: null,
      slideId: false,
      attachmentDialog: false,
      attachmentDescription: null,
      attachmentLink: null,
      attachmentRef: null,
      loading: false,
      attachmentIndex: null,
      homeworks: null,
      givenHomeworks: null,
    };
  },
  mixins: [myfunction],
  props: {
    lessonRef: {
      type: String,
      required: true,
    },
    // query: {
    //   type: Object,
    //   required: true,
    // },
    headerNames: {
      type: Array,
      required: true,
    },
    // bodyNames: {
    //   type: Array,
    //   required: true,
    // },
    addButtonText: {
      type: String,
      required: true,
    },
  },
  watch: {
    showVideo(val) {
      if (val == false) this.videoId = null;
    },
  },
  computed: {
    ...sync("*"),
  },
  methods: {
    checkIfHwDone(item) {
      var found = this.givenHomeworks.find(
        (hw) => hw.ref.path == item.ref.path
      );
      if (found) {
        return true;
      } else {
        return false;
      }
    },
    checkHw(item) {
      if (item.hwGiven) {
        this.loading = true;
        item.ref
          .collection("assignments")
          .doc(this.userData.id)
          .onSnapshot((doc) => {
            this.attachmentLink = doc.data().attachmentLink;
            this.attachmentDescription = doc.data().description;
            this.loading = false;
          });
      }
    },
    submitHw() {
      if (this.attachmentRef && this.attachmentLink) {
        var attachmentLink =
          "<iframe src=" +
          this.attachmentLink.replace("edit?usp=sharing", "embed") +
          " frameborder='0' width='960' height='569' allowfullscreen='true' mozallowfullscreen='true' webkitallowfullscreen='true' ></iframe>";
        this.homeworks[this.attachmentIndex].hwGiven = true;

        this.attachmentRef
          .collection("assignments")
          .doc(this.userData.id)
          .set({
            studentRef: this.userData.ref,
            attachment: attachmentLink,
            description: this.attachmentDescription,
            attachmentLink: this.attachmentLink,
            turnedInAt: new Date(),
          })
          .then(() => {
            this.attachmentDialog = false;
          });
        console.log(this.homeworks);
      }
    },
    isYoutube(videoUrl) {
      return videoUrl.includes("youtube");
    },
    getYoutubeId(url) {
      const regExp =
        /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
      const match = url.match(regExp);

      return match && match[2].length === 11 ? match[2] : null;
    },
    getYoutubeIframe(url) {
      if (
        url != null &&
        url != "" &&
        url.toLowerCase().indexOf("youtube") != -1
      ) {
        var id = this.getYoutubeId(url);
        return "https://www.youtube.com/embed/" + id;
      }
      return null;
    },
    _detail(url) {
      this.addNewItem = false;
      this.videoId = url;
      this.showVideo = !this.showVideo;
    },
    detail(url) {
      this.addNewItem = false;
      this.slideId = url;
      this.showSlide = !this.showSlide;
    },
  },
  created() {
    fb.db
      .doc(this.lessonRef)
      .collection("homeworks")
      .onSnapshot((docs) => {
        this.homeworks = [];
        this.givenHomeworks = [];

        docs.forEach(async (doc) => {
          let homework = doc.data();
          homework.ref = doc.ref;
          homework.id = doc.id;
          await homework.ref
            .collection("assignments")
            .doc(this.userData.id)
            .onSnapshot((xdoc) => {
              if (xdoc.exists) {
                this.givenHomeworks.push(homework);
              }
            });
          this.homeworks.push(homework);
        });
      });
  },
};
</script>
<style>
#iframecontainer iframe {
  width: 100% !important;
}
</style>
