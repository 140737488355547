<template>
  <v-container>
    <v-snackbar
      v-model="snackbar"
      timeout="2000"
      top
      color="bg-gradient-success"
    >
      {{ actionstatus }}
      <template v-slot:action="{ attrs }">
        <v-btn
          class="font-weight-bold"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Xааx
        </v-btn>
      </template>
    </v-snackbar>
    <v-card-text class="px-0 py-0 scoreTables mt-4">
      <v-data-table
        :headers="headers2"
        :items="studentScore"
        :items-per-page="-1"
        :search="search"
        hide-details
        hide-default-footer
      >
        <template slot="item" slot-scope="props">
          <tr>
          
            <td
              v-for="(snum, sindex) in semesterNumber"
              :key="'semesterscore-' + sindex"
              style="width: 1%"
            >
              <p v-if="props.item.scores" style="width: 60px">
                {{ props.item.scores[sindex].finalScore }}
              </p>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card-text>
  </v-container>
</template>
<script>
export default {
  name: "light-table-transparent",
  data() {
    return {
      actionstatus: null,
      search: "",
      snackbar: false,
      headers2: [
      
      
      ],
    };
  },
  computed: {},
  props: {
    studentScore: {
      type: Array,
      required: true,
    },
    userData: {
      type: Object,
      required: true,
    },
    semesterNumber: {
      type: Number,
      required: true,
    },
  },
  methods: {},
  created() {
    for (var i = 0; i < this.semesterNumber; i++) {
      this.headers2.push({
        text: "УД-" + (i + 1),
        value: "score" + (i + 1),
        sortable: true,
      });
    }
  },
};
</script>
<style>
.scoreTables td,
.scoreTables th {
  border: 1px solid #dddddd;
  text-align: left;
  height: 25px !important;
}
</style>
